import * as React from "react";
import MainTemplate from "../templates/MainTemplate";
import MainHero from "../components/organisms/MainHero";
import TheyWorkWithUs from "../components/organisms/TheyWorkWithUs";
import AboutMe from "../components/organisms/AboutMe";

const IndexPage = () => {
  return (
    <MainTemplate isMainPage={true}>
      <MainHero />
      <TheyWorkWithUs />
      <AboutMe />
    </MainTemplate>
  );
};

export default IndexPage;
