import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Headline from '../atoms/Headline';
import Text from '../atoms/Text';

const SectionWrapper = styled.section`
  padding: 0 15px;
  margin: 100px auto;
  max-width: 1300px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }

  /* @media (min-width: ${({ theme }) => theme.smLaptop}) {
    padding: 0 10%;
  } */
`;

const ColumnLeft = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 40%;
  }
`;

const ColumnRight = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 60%;
    padding-left: 6%;
    display: flex;
    flex-direction: column;
  }

  .isCmsContent p {
    margin-bottom: 20px;
  }
`;

const ColumnBtn = styled(Link)`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily};
  color: #000;
  font-size: ${({ theme }) => theme.size18};
  font-weight: ${({ theme }) => theme.medium};
  display: flex;
  align-items: center;
  transition: all 0.3s ${({ theme }) => theme.easing1};
  margin-top: 30px;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
    transform: translateY(-5px);

    svg path {
      fill: rgba(0, 0, 0, 0.7);
    }
    svg circle {
      stroke: rgba(0, 0, 0, 0.7);
    }
  }

  p {
    letter-spacing: 0.255em;
    margin-right: 30px;
  }
`;

const AboutMe = () => {
  const imageData = useStaticQuery(query);
  return (
    <SectionWrapper id='o-mnie'>
      <ColumnLeft>
        <GatsbyImage
          image={imageData.file.childImageSharp.gatsbyImageData}
          alt='About Me'
        />
      </ColumnLeft>
      <ColumnRight id='about-me-trigger'>
        <Headline
          style={{ marginBottom: '30px' }}
          data-aos='headline-fadeup'
          data-aos-anchor='#about-me-trigger'
        >
          {imageData.datoCmsMainPage.title}
        </Headline>
        {/* <Text
          style={{ marginBottom: "20px" }}
          data-aos="headline-fadeup"
          data-aos-delay="200"
          data-aos-anchor="#about-me-trigger"
        >
          Inwestowaniem w nieruchomości zajmuje się od 2016 roku. Na podstawie
          posiadanego doświadczenia wiem jak ważne jest bezpieczeństwo całego
          procesu inwestycyjnego, odpowiednia analiza podatkowa oraz prawna i
          przede wszystkim jednoznaczna umowa. Gwarancja tych wszystkich
          czynników determinuje spokój i pewność transakcji.
        </Text>
        <Text
          style={{ marginBottom: "20px" }}
          data-aos="headline-fadeup"
          data-aos-delay="400"
          data-aos-anchor="#about-me-trigger"
        >
          Natomiast sam produkt inwestycyjny powinien być na swój sposób
          wyjątkowy. Unikatowy przez swoją dodatkową wartość. Pewność, Jakość,
          dbałość i funkcjonalność posiadanego produktu to gwarancja ludzkiego
          zadowolenia.
        </Text>
        <Text
          data-aos="headline-fadeup"
          data-aos-delay="600"
          data-aos-anchor="#about-me-trigger"
        >
          Dzięki przebyciu ciekawej drogi, rozpoczynając od zakupu
          nieruchomości, przechodząc do wykańczania mieszkań pod klucz, a
          kończąc na obsłudze najmu, nabywa się wiedzę o nieruchomościach. Bo
          rynek nieruchomości to stałe podążanie za trendami i bycie blisko
          ludzi.
        </Text> */}

        <Text
          className='isCmsContent'
          dangerouslySetInnerHTML={{
            __html: imageData.datoCmsMainPage.aboutMeText,
          }}
        />
        <ColumnBtn
          to='/kontakt'
          data-aos='headline-fadeup'
          data-aos-delay='800'
          data-aos-anchor='#about-me-trigger'
          aria-label='Przejdź na stronę kontaktową'
        >
          <p>Chcę nawiązać współpracę</p>
          <svg
            width='60'
            height='60'
            viewBox='0 0 43 43'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle
              cx='21.4372'
              cy='21.5464'
              r='20.2732'
              stroke='#FFB703'
              stroke-width='1.5'
            />
            <path
              d='M17.4375 27.1197L18.6509 28.333L25.4375 21.5464L18.6509 14.7598L17.4375 15.9731L23.0108 21.5464L17.4375 27.1197Z'
              fill='#FFB703'
            />
          </svg>
        </ColumnBtn>
      </ColumnRight>
    </SectionWrapper>
  );
};

const query = graphql`
  query AboutMeImage {
    file(name: { eq: "aboutme" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: NONE
          formats: WEBP
        )
      }
    }
    datoCmsMainPage {
      title
      aboutMeText
    }
  }
`;

export default AboutMe;
