import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { MainContext } from '../../context';
import { motion } from 'framer-motion';

const HeroSection = styled.section`
  height: auto;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: 100vh;
  }
`;

const HeroContainer = styled.div`
  height: 100%;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: 0 15px;
  min-height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    .gatsby-image-wrapper {
      transform: scale(1.1);
    }
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 33.33%;
    height: 100%;
  }
`;

const ColumnImg = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  transition: transform 0.8s ${({ theme }) => theme.easing1};
`;

const ColumnTitle = styled(motion.p)`
  color: #fff;
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 130%;
  font-size: ${({ theme }) => theme.size20};
  margin-bottom: 15px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: 100px;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    height: 120px;
    font-size: ${({ theme }) => theme.size28};
    max-width: 390px;
  }
`;

const ColumnText = styled(motion.p)`
  color: #fff;
  font-weight: ${({ theme }) => theme.light};
  line-height: 130%;
  font-size: ${({ theme }) => theme.size15};
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: 150px;
    max-width: 330px;
  }
`;

const ColumnBtn = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily};
  color: #fff;
  font-size: ${({ theme }) => theme.size15};
  display: flex;
  align-items: center;
  transition: all 0.3s ${({ theme }) => theme.easing1};

  &:hover {
    color: rgba(255, 255, 255, 0.7);
    transform: translateY(-5px);

    svg path {
      fill: rgba(255, 255, 255, 0.7);
    }
    svg circle {
      stroke: rgba(255, 255, 255, 0.7);
    }
  }

  p {
    letter-spacing: 0.255em;
    margin-right: 30px;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size20};
  }
`;

const ColumnLinks = styled(motion.div)`
  /* color: #fff;
  font-weight: ${({ theme }) => theme.light};
  line-height: 130%;
  font-size: ${({ theme }) => theme.size16};
  text-align: center;
  margin-bottom: 30px; */

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: 227px;
    max-width: 380px;
  }
`;

const ColumnLink = styled(Link)`
  color: #fff;
  font-weight: ${({ theme }) => theme.medium};
  /* line-height: 130%; */
  font-size: ${({ theme }) => theme.size15};
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 1s ${({ theme }) => theme.easing1};

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 15px;
    min-width: 19px;
  }
`;

const MainHero = () => {
  const imageQuery = useStaticQuery(query);
  const { isLoadingFinished } = useContext(MainContext);

  return (
    <HeroSection>
      <HeroContainer>
        <Column>
          <ColumnTitle
            initial={{ y: '-100%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.5, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            {imageQuery.datoCmsMainPage.hero[0].title}
          </ColumnTitle>
          <ColumnLinks
            initial={{ y: '50%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.5, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            <ColumnLink to='/oferta/zostan-partnerem-biznesowym-i-pomnazaj-oszczednosci'>
              <svg
                width='19'
                height='19'
                viewBox='0 0 19 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.8125 16.4768H2.8125V2.47681H9.8125V0.476807H2.8125C1.7025 0.476807 0.8125 1.37681 0.8125 2.47681V16.4768C0.8125 17.5768 1.7025 18.4768 2.8125 18.4768H16.8125C17.9125 18.4768 18.8125 17.5768 18.8125 16.4768V9.47681H16.8125V16.4768ZM11.8125 0.476807V2.47681H15.4025L5.5725 12.3068L6.9825 13.7168L16.8125 3.88681V7.47681H18.8125V0.476807H11.8125Z'
                  fill='white'
                />
              </svg>
              Zostań partnerem biznesowym i pomnażaj oszczędności inwestując w
              nieruchomości
            </ColumnLink>

            <ColumnLink to='/oferta/stworz-z-mieszkania-produkt-inwestycyjny-i-zarabiaj-na-najmie-znacznie-wiecej'>
              <svg
                width='19'
                height='19'
                viewBox='0 0 19 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.8125 16.4768H2.8125V2.47681H9.8125V0.476807H2.8125C1.7025 0.476807 0.8125 1.37681 0.8125 2.47681V16.4768C0.8125 17.5768 1.7025 18.4768 2.8125 18.4768H16.8125C17.9125 18.4768 18.8125 17.5768 18.8125 16.4768V9.47681H16.8125V16.4768ZM11.8125 0.476807V2.47681H15.4025L5.5725 12.3068L6.9825 13.7168L16.8125 3.88681V7.47681H18.8125V0.476807H11.8125Z'
                  fill='white'
                />
              </svg>
              Stwórz z mieszkania produkt inwestycyjny i zarabiaj na najmie
              znacznie więcej
            </ColumnLink>

            {/* <ColumnLink to='/oferta/mieszkaniowe-produkty-inwestycyjne'>
              <svg
                width='19'
                height='19'
                viewBox='0 0 19 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.8125 16.4768H2.8125V2.47681H9.8125V0.476807H2.8125C1.7025 0.476807 0.8125 1.37681 0.8125 2.47681V16.4768C0.8125 17.5768 1.7025 18.4768 2.8125 18.4768H16.8125C17.9125 18.4768 18.8125 17.5768 18.8125 16.4768V9.47681H16.8125V16.4768ZM11.8125 0.476807V2.47681H15.4025L5.5725 12.3068L6.9825 13.7168L16.8125 3.88681V7.47681H18.8125V0.476807H11.8125Z'
                  fill='white'
                />
              </svg>
              Mieszkaniowe produkty inwestycyjne
            </ColumnLink>
            <ColumnLink to='/oferta/mieszkania-na-wynajem-i-obsluga'>
              <svg
                width='19'
                height='19'
                viewBox='0 0 19 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.8125 16.4768H2.8125V2.47681H9.8125V0.476807H2.8125C1.7025 0.476807 0.8125 1.37681 0.8125 2.47681V16.4768C0.8125 17.5768 1.7025 18.4768 2.8125 18.4768H16.8125C17.9125 18.4768 18.8125 17.5768 18.8125 16.4768V9.47681H16.8125V16.4768ZM11.8125 0.476807V2.47681H15.4025L5.5725 12.3068L6.9825 13.7168L16.8125 3.88681V7.47681H18.8125V0.476807H11.8125Z'
                  fill='white'
                />
              </svg>
              Mieszkania na wynajem i obsługa
            </ColumnLink> */}
          </ColumnLinks>
          <ColumnImg
            image={imageQuery.heroColumnOne.childImageSharp.gatsbyImageData}
            alt='Hero Image'
          />
        </Column>
        <Column>
          <ColumnTitle
            initial={{ y: '-100%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.6, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            {imageQuery.datoCmsMainPage.hero[1].title}
          </ColumnTitle>
          <ColumnText
            initial={{ y: '50%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.8, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: imageQuery.datoCmsMainPage.hero[1].subtitle,
              }}
            />
          </ColumnText>
          <ColumnBtn
            to='/oferta/mieszkanie-wykonczone-pod-klucz/'
            aria-label='Sprawdź ofertę mieszkań wykończonych pod klucz'
          >
            <p>Sprawdź</p>
            <svg
              width='43'
              height='43'
              viewBox='0 0 43 43'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                cx='21.4372'
                cy='21.5464'
                r='20.2732'
                stroke='white'
                stroke-width='1.5'
              />
              <path
                d='M17.4375 27.1197L18.6509 28.333L25.4375 21.5464L18.6509 14.7598L17.4375 15.9731L23.0108 21.5464L17.4375 27.1197Z'
                fill='white'
              />
            </svg>
          </ColumnBtn>
          <ColumnImg
            image={imageQuery.heroColumnTwo.childImageSharp.gatsbyImageData}
            alt='Hero Image'
          />
        </Column>
        <Column>
          <ColumnTitle
            initial={{ y: '-100%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.7, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            {imageQuery.datoCmsMainPage.hero[2].title}
          </ColumnTitle>
          <ColumnText
            initial={{ y: '50%', opacity: 0 }}
            animate={isLoadingFinished ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 1, duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: imageQuery.datoCmsMainPage.hero[2].subtitle,
              }}
            />
          </ColumnText>
          <ColumnBtn
            to='/oferta/doradztwo-prawne-i-podatkowe'
            aria-label='Sprawdź ofertę z zakresu doradztwa prawnego i podatkowego'
          >
            <p>Sprawdź</p>
            <svg
              width='43'
              height='43'
              viewBox='0 0 43 43'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                cx='21.4372'
                cy='21.5464'
                r='20.2732'
                stroke='white'
                stroke-width='1.5'
              />
              <path
                d='M17.4375 27.1197L18.6509 28.333L25.4375 21.5464L18.6509 14.7598L17.4375 15.9731L23.0108 21.5464L17.4375 27.1197Z'
                fill='white'
              />
            </svg>
          </ColumnBtn>
          <ColumnImg
            image={imageQuery.heroColumnThree.childImageSharp.gatsbyImageData}
            alt='Hero Image'
          />
        </Column>
      </HeroContainer>
    </HeroSection>
  );
};

const query = graphql`
  query HeroImageQuery {
    heroColumnOne: file(name: { eq: "hero_column_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, formats: WEBP)
      }
    }
    heroColumnTwo: file(name: { eq: "hero_column_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, formats: WEBP)
      }
    }
    heroColumnThree: file(name: { eq: "hero_column_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, formats: WEBP)
      }
    }
    datoCmsMainPage {
      hero {
        subtitle
        title
      }
    }
  }
`;

export default MainHero;
